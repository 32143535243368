/* Reference only */
/* :do-not-use-these-vars {
  --breakpoint-sm: 30em;
  --breakpoint-md: 48em;
  --breakpoint-lg: 62em;
  --breakpoint-xl: 80em;

} */

html,
body {
  background-color: #F5F8FA !important;
  line-height: unset !important;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

b {
  font-family: ProximaNova-SemiBold;
}

button {
  outline: none;
  display: inline-flex;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

* {
  box-sizing: border-box;
  font-family: 'ProximaNova-Regular';
  letter-spacing: -0.16px;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'ProximaNova-Black';
  src: url('https://s3.amazonaws.com/tide-assets/proxima-nova-black.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('https://s3.amazonaws.com/tide-assets/proxima-nova-bold.otf')
    format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Light';
  src: url('https://s3.amazonaws.com/tide-assets/proxima-nova-light.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('https://s3.amazonaws.com/tide-assets/proxima-nova-regular.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('https://s3.amazonaws.com/tide-assets/proxima-nova-semibold.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Extrabold';
  src: url('https://s3.amazonaws.com/tide-assets/proxima-nova-extrabold.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}
