.Button {
  padding: 6px 24px;
  border-radius: 3px;
  background-color: var(--drift-dds-colors-blue8);
  border: 1px solid var(--drift-dds-colors-blue8);
  height: 32px;
  font-size: 14px;
  font-family: ProximaNova-Semibold, sans-serif;
  color: var(--drift-dds-colors-gray1);
  text-align: center;
  transition: all 0.3s ease;
}

.Button[disabled] {
  background-color: var(--drift-dds-colors-gray2);
  border-color: var(--drift-dds-colors-gray3);
  color: var(--drift-dds-colors-gray3);
  cursor: not-allowed;
  pointer-events: none;
}

.Button_Small {
  padding: 5px 4px;
  font-size: 12px;
  height: 24px;
}

.Button:hover {
  background-color: var(--drift-dds-colors-blue9);
  border-color: var(--drift-dds-colors-blue9);
}

.Button_Tertiary {
  background-color: var(--drift-dds-colors-gray1);
  border-color: var(--drift-dds-colors-gray3);
  color: var(--drift-dds-colors-gray12);
}

.Button_Tertiary:hover {
  background-color: var(--drift-dds-colors-gray2);
  border-color: var(--drift-dds-colors-gray3);
  color: var(--drift-dds-colors-gray12);
}

.Button_Utility {
  background-color: transparent;
  border-color: transparent;
  color: var(--drift-dds-colors-gray12);
}

.Button_Utility:hover {
  background-color: rgb(70 82 89 / 10%);
  border-color: transparent;
}

.Button_Danger {
  background-color: var(--drift-dds-colors-red8);
  border-color: var(--drift-dds-colors-red8);
  color: var(--drift-dds-colors-gray1);
}

.Button_Danger:hover {
  background-color: var(--drift-dds-colors-red9);
  border-color: var(--drift-dds-colors-red9);
}
