.tide-select--label {
  color: #353f45;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 3px;
  display: block;
  font-family: 'ProximaNova-Semibold';
}

.tide-select--description {
  color: #5d6a74;
  font-size: 12px;
  margin: 8px 0 0 0;
}

.tide-select--option:hover {
  cursor: pointer;
}

.tide-select-dropdown-indicator.tide-icon path {
  fill: #4e5c65;
}

.tide-select-menu {
  animation: fade--in 0.2s ease-in-out;
}

@keyframes fade--in {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
