.ErrorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 432px;
  height: 100vh;
  padding: 16px;
  text-align: center;
}

.DizzyBotBackground {
  background-color: #c8ff00;
  height: 32px;
  width: 32px;
  margin: 4px;
  position: fixed;
}

.DizzyBotContainer svg {
  position: relative;
  z-index: 1;
}

.ErrorPageTitle {
  color: grey;
  font-size: 20px;
  margin-top: 16px;
  font-family: ProximaNova-Semibold, sans-serif;
}

.ErrorPageDescription {
  color: #687882;
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
}

.ErrorPageCTA {
  margin-top: 24px;
}
