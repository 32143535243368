.CalendarMonth_table {
  border-collapse: separate;
  margin-top: 20px;
}

.CalendarMonth_caption strong {
  font-size: 21px;
  color: #394f5a;
}

.DayPicker__withBorder {
  border-radius: 6px;
  box-shadow: 0 2px 23px #222;
  margin: 0 auto;
}

div.DayPicker_weekHeader {
  top: 70px;
}

td.CalendarDay {
  border: 1px solid white;
  padding: 0;
  font-size: 16px;
}

td.CalendarDay > div {
  height: 90%;
  padding-top: 10%;
}

td.CalendarDay:hover {
  background-color: rgba(82, 40, 150, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.01);
}

td.CalendarDay__selected,
td.CalendarDay__selected:active,
td.CalendarDay__selected:hover {
  background-color: rgba(82, 40, 150, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.01);
  color: #394f5a;
}

li.DayPicker_weekHeader_li small {
  font-size: 16px !important;
  color: #7695a5;
}

td.CalendarDay__blocked_calendar,
td.CalendarDay__blocked_out_of_range {
  cursor: default;
}

td.CalendarDay__blocked_calendar,
td.CalendarDay__blocked_calendar:active,
td.CalendarDay__blocked_calendar:hover {
  color: #b5d2e1;
  background-color: white;
}

.DayPickerNavigation_button__default {
  border: 1px solid white;
}

.DayPickerNavigation_leftButton__horizontal {
  left: 32px;
}
.DayPickerNavigation_rightButton__horizontal {
  right: 32px;
}
